.c-dashboardInfo {
  margin-bottom: 15px;
}
.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
}
.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
  text-align: center;
}
.c-dashboardInfo span {
  display: block;
}
.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 64px;
  color: #323c43;
}
.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}
.c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}
.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}
.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}
.c-dashboardInfo:nth-child(5) .wrap:after {
  background: linear-gradient(81.67deg, #e74c3c 0%, #f1c40f 100%);
}
.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
.pointer {cursor: pointer;}
.dashProfileBox{
  padding: 10px;
  background-color: #ffffff;
  margin: 20px 0px;

}
.dashImgBox{
  display: flex;
}
.dashNameBox{
  padding: 0px 20px;

}
.dashName{
  font-weight: 600;
  font-size: 1.5em;
  line-height: 50px;
  color: #323c43;
}
.dashType{
  color: #323c43;
}
.imgMain{
  width: 100px;
}
.imgMain img {
  width: 100px;
  border-radius: 10px;
}
@media
only screen 
and (max-width: 760px), (min-device-width: 768px) 
and (max-device-width: 1024px)  {
  .c-dashboardInfo__count {
    font-size: 1.5em;
  }
}
