@import url("https://fonts.googleapis.com/css?family=Montserrat:600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:800");
@import url("https://fonts.googleapis.com/css?family=Montserrat");



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.buttonPrimary{
  width: 50%;
  font-size: 14px;
  color: white;
  box-shadow: 0 10px 20px 0 rgba(80, 123, 252, 0.50);
  font-weight: 300;
  /* padding: 8px 0; */
  border-radius: 100px;
  transition: 300ms;
  cursor: pointer;
  background-color: #507BFC;
  margin: 0px 10px;
  max-width: 200px;
}

.buttonDanger{
  width: 50%;
  font-size: 14px;
  color: white;
  box-shadow: 0 10px 20px 0 #e74c3c;
  font-weight: 300;
  /* padding: 8px 0; */
  border-radius: 100px;
  transition: 300ms;
  cursor: pointer;
  background-color: #e74c3c;
  margin: 0px 10px;
  max-width: 200px;
}

.bgPrimary{
  background-color: #4b6584  !important;
  --bs-bg-opacity:1;
  border-bottom-color: #FFFFFF  !important;
  border-bottom-width: 1px;
  border-style: solid;
}
.bottomFotter{
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
}
.headerRow{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  border: 1px #e4e4e4 solid;
  padding: 10px;
  background-color:#50ac00  !important;
  border-color: #FFFFFF  !important;
  /* justify-content: center; */
  align-items: center;

}
.headerCol h2 {
  font-size: 16px !important;
  color:#ffff;
  font-weight: bold;
  margin: 0px !important;
}
.headerCol {
  /* width: 50%; */
}
.addCol {
  display: flex;
  width: 50%;
  justify-content: end;
}



.nav-link{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.fixed-top {
  /* z-index: 0; */
}
.Toastify {
  position: absolute;
  z-index: 999999;
}
.Toastify__toast-container{
  z-index:1021;
}
.capitalize{
text-transform: capitalize;
}
.profileLetter{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #fff;
    border: 2px solid #fff;
    text-align: center;
    font-size: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #3498db;
}
.thumbnail-image{
  height: 50px;
  border-radius:100% ;
  border : 2px #ffff solid;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  z-index: 999999;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 12px;
  }
  .card-title{
    font-size: 12px;
  }
  .card-text{
    font-size: 12px !important;
  }
  .card-body{
    font-size: 12px !important;
  }
  .buttonPrimary{
    width: 100%;
    font-size: 10px;
  }
  .buttonDanger{
    width: 100%;
    font-size: 10px;
  }
}

