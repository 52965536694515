

.auctionBox .detailBox {
  color: white;
}
.detailBoxDashboard{
  width: 40%;
}
.detailBoxDashboard .profile-main-box-auction{
  width: 250px !important;
  padding: 5px;
}
.detailBoxDashboard .profile-box-auction img{
 width: 30px;
 height: 30px;
}
.detailBoxDashboard  .teamNameAuction{
  font-size: 0.8rem;
}
.detailBoxDashboard .auctionListLabel{
  font-size: 10px;
}
.detailBoxDashboard .auctionListNumber{
  font-size: 12px;
}
.detailBoxDashboard .profile-box-auction{
background: transparent;
}
.detailBoxDashboard .auctionListNextPlayer{
  border-radius: 5px;
}
.detailBoxDashboard .tournamentDetailBoxAuction {
  padding: 5px 5px;
  border-radius: 16px;
  box-shadow: none;
  background: transparent;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: absolute;
  height: 650px;
  margin-top: 40px;
}
.auctionDetailBox .playerProfileStats{
width: 100% !important;
}
.tournamentDetailBoxAuction {
  padding: 25px 5px;
  min-height: 528px;
  border-radius: 16px;
  box-shadow: 0 24px 64px rgb(0 0 0 / 4%);
  overflow: scroll;
  /* background: #f25f5c; */
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  /* background: url(/src/images/Background/8.png); */
  background: url("https://sportzmitra-public.s3.ap-south-1.amazonaws.com/Background.png");

  position: absolute;
  overflow-y: scroll;
  scroll-behavior: smooth;


}
.profilePic{
  background-size: 100% 100%!important;
}

.inputBox .flabel {
  font-weight: bold;
  padding: 10px 0px;

}
.inputBox .wlabel{
  font-weight: bold;
  padding: 10px 0px;

}
.tournamentDetailBoxAuction .card {
  font-weight: bold;
  color: #2c3e50;
}

.tournamentDetailBoxAuctionTeam {
  padding: 10px;
  min-height: 528px;
  border-radius: 16px;
  margin-left: 15px;
  box-shadow: 0 24px 64px rgb(0 0 0 / 4%);
  background: #FFFF;
  width: 40%;
}

.blogSlider {
  height: 100%;
}


.auctionBox {
  display: flex;
  flex-flow: row;
  min-height: 200px;
  margin-top: 15px;
  background: #e4e4e4;
  box-shadow: 0 2px 6px 0 rgb(47 83 151 / 10%);
  border-radius: 3px;
  padding: 5px;
}

.tournamentBox {
  display: flex;
  flex-flow: column;
  height: 200px;
  width: 40%;
}

.flabel {
  color: #2c3e50;
  font-size: 16px;
}
.wlabel {
  color: #FFFFFF;
  font-size: 16px;
}

.tournamentPlayer {
  display: flex;
  flex-flow: column;
  height: 200px;
  width: 60%;
}

.teamCard {
  background-color: #ffff;
  width: 200px;
  margin: 10px 0px;
}

.teamItem {
  color: #2c3e50;
  font-size: 12px;
  border-top: 1px #e4e4e4 solid;
  padding: 5px;
  bottom: 12px;
  width: 200px;
}

.teamName {
  padding: 10px;
  margin-bottom: 15px;
}

.teamName h3 {

  color: #2c3e50;
  font-weight: bold;

}

.card-img-top1 {
  height: 150px !important;
}

.teamLogo {
  height: 100px;
  width: 100px;
}

.inputMainBoxFullScreen {
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;

}
.inputMainBox {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

}

.inputBox {
  margin: 10px;
  position: relative;
  z-index: 2;
}
.btn{
  position: relative;
  z-index: 2;
}
.blogSlider .teamBox {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.teamBoxMain {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}


.blogSlider .teamBox .detailBox {
  padding: 20px;
}

.profile-info p,
b {
  line-height: 1.5rem;
}

.imgBox {
  width: 20%;
}

.auctionBox .detailBox {
  width: 60%;
}

.auctionBox .playerDetailBox {
  padding: 10px;
  width: 70%;
}

.playerDetailBox h1 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-top: 0;
  margin-bottom: 5%;
}

.arrowBox {
  color: black;
  display: flex;
  /* position: absolute; */
  /* width: 100%; */
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.playerDetailBox h4 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-top: 0;
  margin-bottom: 5%;
}

.bidBox {
  border: 1px #bdc3c7 solid;
  width: 100%;
  padding: 10px;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  background: #507BFC;
  color: #ffff;
}

.basePrice {
  font-weight: bold;
  line-height: 1.5rem;
  color: #2c3e50;
}

.buttonBox {
  display: flex;
  margin: 20px;
  justify-content: space-evenly;
}
.buttonBoxFullScreen {
  display: flex;
  margin: 20px;
  justify-content: space-evenly;
  align-items: center;
    width: 30%;
}

.page-wrapper-auction {
  font-family: 'Lato', sans-serif;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;

}

.profile-main-box-auction {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgb(47 83 151 / 10%);
  border-radius: 10px;
  margin: 6px 6px;
  text-align: left;
  transition: 300ms;
  width: 500px;
  justify-content: space-between;
  padding: 16px;
}

.profile-box-auction {
  background: #FFFFFF;
  text-align: left;
  display: flex;
  align-items: center;
  display: flex;
  padding: 5px;
}

.profile-box-auction img {
  width: 120px;
  height: 120px;
}


.profile-detail-auction {
  border-top-color: #2c3e50;
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
}

.profile-detail-auction-text {
  color: #2c3e50;
  border-bottom: 1px #2c3e50 solid;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;

}

.profile-detail-auction-text span {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #12263a;
}

.profile-box-textBox {
  padding: 1px;
}

.profile-box-auction span {
  color: #455a64;
  font-size: 10px;
}

.teamNameAuction {
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #12263a;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .auctionBox {
    flex-direction: column;
  }

  .auctionBox .detailBox {
    width: 100%;
  }

  .tournamentDetailBoxAuctionTeam {
    width: 100%;
  }

  .tournamentDetailBoxAuctionTeam {
    width: 100%;
  }

  .user-link {
    font-size: .50em;
  }

  .user-list tbody td .user-subhead {
    font-size: .50em;
  }

  .main-box .table tbody>tr>td {
    font-size: .50em;
  }

  .user-list tbody td>img {
    height: 25px;
    width: 25px;
  }
}
.auctionLogoName{
 font-size: 3.2rem;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  color: #FFFFFF;
  background-color: rgba(0,0,0,0.5);

  text-align: center;
  font-family: 'Lilita One', cursive;
}

.auctionDetailBox {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  width: 100%;
  height: 100%;
  justify-content: space-around
}
.auctionName {
  color: #ffff;
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 1;
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

.auctionHeader {
  display: flex;
  justify-content: space-evenly;
  background: #50ac00;
  color: #fff;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
}

.imgBoxAuction {
  width: 150px;
}

.aboutUs {
  display: block;
  color: #fff;
  font-size: 25px !important;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.img-display {
  overflow: hidden;

}

.img-showcase {
  display: flex;
  width: 100%;
  transition: all 0.5s ease;
  height: 500px;
  background-size: cover;
}

.img-showcase img {
  min-width: 100%;
}

.img-select {
  display: flex;
}

.img-item {
  margin: 0.3rem;
}

.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
  margin-right: 0;
}

.img-item:hover {
  opacity: 0.8;
}

.product-content {
  padding: 2rem 1rem;
}

.product-title {
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #12263a;
  margin: 1rem 0;
}

.product-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 80px;
  background: #12263a;
}

.product-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  background: #256eff;
  color: #fff;
  padding: 0 0.3rem;
  transition: all 0.5s ease;
}

.product-link:hover {
  opacity: 0.9;
}

.product-rating {
  color: #ffc107;
}

.product-rating span {
  font-weight: 600;
  color: #252525;
}

.product-price {
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
}

.product-price span {
  font-weight: 400;
}

.last-price span {
  color: #f64749;
  text-decoration: line-through;
}

.new-price span {
  color: #256eff;
}

.product-detail h2 {
  text-transform: capitalize;
  /* color: #12263a; */
  color: #FFFFFF;
  padding-bottom: 0.6rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.product-imgs h2 {
  text-transform: capitalize;
  color: #12263a;
  padding-bottom: 0.6rem;
  font-size: 1.2rem;
}

.product-detail p {
  font-size: 0.9rem;
  padding: 0.3rem;
  /* opacity: 0.8; */
}

.product-detail ul {
  margin: 1rem 0;
  font-size: 0.9rem;
}

.product-detail ul li {
  margin: 0;
  list-style: none;
  background: url(https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/checked.png) left center no-repeat;
  background-size: 18px;
  padding-left: 1.7rem;
  margin: 0.4rem 0;
  font-weight: 600;
  opacity: 0.9;
  font-size: 20px;
  background: white;
  color: black;

}

.product-detail ul li span {
  font-weight: 500;
}

.purchase-info {
  margin: 1.5rem 0;
}

.purchase-info input,
.purchase-info .btn {
  border: 1.5px solid #ddd;
  border-radius: 25px;
  text-align: center;
  padding: 0.45rem 0.8rem;
  outline: 0;
  margin-right: 0.2rem;
  margin-bottom: 1rem;
}

.purchase-info input {
  width: 60px;
}

.purchase-info .btn {
  cursor: pointer;
  color: #fff;
}

.purchase-info .btn:first-of-type {
  background: #256eff;
}

.purchase-info .btn:last-of-type {
  background: #f64749;
}

.purchase-info .btn:hover {
  opacity: 0.9;
}

.social-links {
  display: flex;
  align-items: center;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #000;
  border: 1px solid #000;
  margin: 0 0.2rem;
  border-radius: 50%;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.5s ease;
}

.social-links a:hover {
  background: #000;
  border-color: transparent;
  color: #fff;
}
.auctionListNextPlayer{
  display: flex;
  color: white;
  justify-content: center;
  background-color: red;
  border-radius: 2px;
  align-items: center;

}
.auctionListNextPlayer .auctionListLabel{
  color: white;
}
.auctionListNextPlayer .auctionListNumber{
  color: white;
}

.auctionList {
  display: flex;
  padding-top: 5px;
  color: black;
  justify-content: space-between;
  border-top: 1px solid #e4e4e4;

}

.auctionListCol {
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auctionListLabel {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
  color: #12263a;
}
.auctionListNumber{
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
  color: #12263a;
}
.inputGroupBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffc107;
  color: #ffff;
  cursor: pointer;
}

.inputGroupBox .btn {
  color: #ffff;
}

.inputGroupBoxDanger {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dc3545;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  color: #ffff;
  cursor: pointer;
}
.inputGroupSold {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #27ae60;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  color: #ffff;
}

.inputGroupBox .btn {
  color: #ffff;
}

.mouse {
  cursor: pointer;
  text-align: center;
}

.tabDiv {
  background: #50ac00;
  padding: 10px;
}

.custom-canvas {
  width: 100%;
  height: 100%;
  background: var(--secondary-color);
  position: absolute;
}

.soldBox {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  flex-direction: column;
}

.soldImageBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.soldImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid #e74c3c;
}

.soldMain {
  display: flex;
  justify-content: center;
  flex-flow: column;
  justify-items: center;
  width: 100%;
}

#congratulationsModal .modal-body {
  background-color: #34495e;

}

.soldName {
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #f1c40f;
  margin: 1rem 0;
}

.soldNameSold {
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #FFFFFF;
  margin: 1rem 0;
}

.soldTeamName {
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #27ae60;
  margin: 1rem 0;
}

.soldNameBid {
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #FFFFFF;
  margin: 1rem 0;
}


.playerProfileBox {
  background: white;
  color: #000;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 25px;
  margin: 5px 25px;
  height: 100%;
}
.playerNameBox{
  background: #FFFFFF;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  width: 100%;
  border-radius: 0px 0px 25px 25px;
}
.auctionPlayerNameFullscreen {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  color: #FFFFFF;
  background-color: #50ac00;
  text-align: center;
  font-family: 'Lilita One', cursive;
}
.auctionTeamNameFullscreen {
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  color: #FFFFFF;
  background-color: #e74c3c;
  text-align: center;
  font-family: 'Lilita One', cursive;
  margin-bottom: 1rem;
}
.product-detail{
  background-color: rgba(0,0,0,0.5);
  padding:10
}

.auctionPlayerName {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  color: #12263a  ;
  font-family: 'Lilita One', cursive;

}
.auctionPlayerCat {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Lilita One', cursive;
  position: relative;
  color: #f1c40f  ;
  text-align:center ;
}
.playerBoxDetail{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /* background: url(https://e1.pxfuel.com/desktop-wallpaper/523/619/desktop-wallpaper-walakea-cricket-cricket-pitch.jpg); */
  /* background: #f25f5c; */
  background: url(/src/images/back.jpeg);
  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;

}
.playerProfileImgBoxFullScreen{
  width: 20rem;
  height: 100%;
  display: flex;
  align-items:center;
  flex-direction: column;

}
.playerImgBoxFullscreen{
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  box-shadow:
  inset 0px 10px .5em rgba(0,0,0,.25),
  inset .1em 0px .1em rgba(0,0,0,.1),
  inset -0.1em 0px .1em rgba(0,0,0,.05),
  0 .3em .2em #fff;
  border: 10px solid #ffffff;
  width: 20rem;
  height: 30rem;
  background-color: white;
}

.playerProfileImgBoxFullScreen img {
  max-width: 100%;
  height: 100%;
}
/* .playerProfileImgBoxFullScreen img {
  padding: var(--s);
  border: calc(2*var(--s)) solid #0000;
  outline: 1px solid #1B1C18;
  outline-offset: calc(-1*var(--s));
  background: conic-gradient(from 90deg at 1px 1px,#0000 25%,#1B1C18 0);
}
.playerProfileImgBoxFullScreen img {
  width: 100%;
  transition: 0.5s all ease-in-out;
  object-fit: cover;
}

.playerProfileImgBoxFullScreen:hover img {
  transform: scale(2);
  height: 25rem;
  width: auto;
  left: 50%;
  right: 50%;
  position: relative;
  background-color: #FFFFFF;
} */
.playerProfileImgBox{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.playerImgBox{
  padding: 20px;
  height: 25rem;
  width: 25rem;
  z-index: 1000;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}
.playerImgBox img {
  max-width: 100%;
  height: 100%;
}
.playerImgBox img {
  --s: 10px; /* control the size */
  padding: var(--s);
  border: calc(2*var(--s)) solid #0000;
  outline: 1px solid #1B1C18;
  outline-offset: calc(-1*var(--s));
  background: conic-gradient(from 90deg at 1px 1px,#0000 25%,#1B1C18 0);
}
.playerImgBox img {
  width: 100%;
  transition: 0.5s all ease-in-out;
  object-fit: cover;
}

.playerImgBox:hover img {
  transform: scale(2);
  height: 25rem;
  width: auto;
  left: 50%;
  right: 50%;
  position: relative;
  background-color: #FFFFFF;
}
.playerProfileStats{
  width: 100%;
}

.two{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80%;
}
.container-fluid{
  padding: 0;
}
.compMain1{
  padding: 0;
  /* height: 100%; */
  /* width: 1800; */
  background-color: #34ADFF;
  color: white;
  /* background-position:cover; */
  background: url("https://sportzmitra-public.s3.ap-south-1.amazonaws.com/Background.png");
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  
}
.topHeader{
  background-color: rgba(0,0,0,0.5);
  padding: 10;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}
.three{
  background-color: rgba(0,0,0,0.5);
  min-height: 100px;
  display: flex;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.auctionLogo{
  width: 200;
}


@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}
@media screen and (min-width: 992px) {
  .blogSlider .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  .blogSlider .card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .blogSlider .product-imgs {
    flex-direction: column;
  }

  .blogSlider .product-content {
    padding-top: 0;
  }

  .auctionName {
    font-size: 20px;
  }
  .auctionLogoName{
    font-size: 50px;
  }

  .soldBox {

    flex-direction: row;
  }

  .soldMain {
    width: 50%;
  }

  .soldImageBox {
    width: 50%;

  }

  .soldImg {
    width: 500px;
    height: 500px;
  }


  .soldName {
    font-size: 5rem;
  }

  .soldNameSold {
    font-size: 3rem;
  }

  .soldTeamName {
    font-size: 5rem;
  }

  .soldNameBid {
    font-size: 5rem;
  }
  .playerProfileImgBox{
    width: 60%;
  }
  .playerProfileStats{
    width: 40%;
  }
  .playerBoxDetail{
    flex-direction: row;
  }
}
dt {
  font-weight: bold;
}

dl,
dd {
  font-size: 0.9rem;
}

dd {
  margin-bottom: 1em;
}
