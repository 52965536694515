

.detailBoxMain {
  display: flex;
  flex-wrap: nowrap;
  /* border: 1px red solid; */
  margin-top: 100px;
}

.detailBoxMain .detailBox {
  width: 40%;
  /* border: 1px red solid; */
}

.teamList {
  padding: 20px;
  justify-content: start;
  border: 1px #e4e4e4 solid;
  display: flex;
  width: 100%;
  flex-direction: row;
  font-weight: bold;
  background-color: #ecf0f1;
  justify-content: left;
  align-items: center;
}
.teamList .arrow{
  padding: 0px 10px;
}
.teamBox {
  width: 60%;
  min-height: 72px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #F5F7FA;
  padding: 0 5%;
  /* border: 1px red solid; */
}

.tournamentDetailBox {
  padding: 10px;
  min-height: 528px;
  background-color: #f39c12;
  border-radius: 16px;
  box-shadow: 0 24px 64px rgba(0, 0, 0, 4%);
}
.tournamentBanner{
  width: 500px;
  height: 250px;
}
.tournamentLogo{
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px #ffff solid;
}
.tournamentLogBox{
  display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
}

.tournamentName {
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 2px;
  font-size: 25px;
  font-weight: 600;
  color: #ffff;
  text-align: center;
}

.startIn {
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 600;
  color: #ffff;
  text-align: center;
}


.clock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock div {
  margin: 5px;
  position: relative;
}

.clock span {
  width: 80px;
  height: 60px;
  background: #2ec4b6;
  border-radius: 10px 10px 0 0;
  color: #fdfffc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.clock .text {
  height: 30px;
  margin-top: 10px;
  font-size: 10px;
  border-radius: 0 0 10px 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #fff;
  color: #011627;
}

.clock #ampm {
  bottom: 0;
  position: absolute;
  width: 60px;
  height: 30px;
  font-size: 20px;
  background: #e71d36;
}

.am-pm {
  border-radius: 2px !important;
}

.tournamentTableBox {
  padding: 10px
}

.tableBoxRowTableBoxRow {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0px;
}

.cardView {
  background-color: #fff;
  border-radius: 15px;
  padding: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 200px;
}

.cardView:hover {
  background-color: #e6e6e6;
}

.item h5 {
  color: #2c3e50;
  font-size: 1rem;
  font-weight: 800;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}

.name h3 {
  color: #000;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.page-wrapper {
  font-family: 'Lato', sans-serif;
  min-height: 100vh;
  background-color: #F5F7FA;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  padding-left: calc(5% - 16px);
  padding-right: calc(5% - 16px);
  /* padding: 0 5%; */
}

.profile-box {
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.10);
  border-radius: 3px;
  margin: 16px 16px;
  padding: 16px 16px;
  width: 250px;
  text-align: center;
  transition: 300ms;
}

.profile-box:hover {
  /* box-shadow: 0 2px 6px 0 rgba(47,83,151,0.40); */
  box-shadow: 0 0 20px 9px rgba(0, 0, 0, 0.03);
}

.btn-container {
  display: flex;
  justify-content: center;
}

.profile-box img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.10);
}

.profile-box #logo {
  width: 127px;
  height: 100%;
  border-radius: 0%;
  background-color: white;
  margin-right: 32px;
  box-shadow: none;
}

.profile-box #searchField {
  width: 100%;
  background: rgba(245, 247, 250, 0.20);
  border: 1px solid #F5F7FA;
  border-radius: 6px;
  padding: 8px 16px;
}

.profile-box ::placeholder {
  color: #98A0A6;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  font-weight: 300;
}

.profile-box h3 {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
}

.profile-box h4 {
  transition: 500ms;
  font-weight: 300;
  font-size: 14px;
  margin-top: 8px;
  color: #98A0A6;
  cursor: pointer;
}

.profile-box h4:hover {
  color: #507BFC;
  text-decoration: underline;
}

#assign,
#view {
  width: 50%;
  font-size: 14px;
  color: white;
  box-shadow: 0 10px 20px 0 rgba(80, 123, 252, 0.50);
  font-weight: 300;
  padding: 8px 0;
  border-radius: 100px;
  transition: 300ms;
  cursor: pointer;
  background-color: #507BFC;
  margin: 0px 10px;
}



.profile-box #assign {
  float: left;
}

.profile-box #view {
  float: right;
}

.profile-box i {
  margin-right: 8px;
}
@media (max-width: 768px) {
  .detailBoxMain .detailBox{
    width: 100%;
  }
  .detailBoxMain{
    flex-flow: column;
  }
  .teamBox{
    width: 100%;
    margin:10px 0px
  }
  .tableBoxRowTableBoxRow{
    flex-flow: column;
  }
  .cardView{
  margin:10px 0px
  }
}