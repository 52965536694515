.tournamentList{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.clubItem{
  padding: 10px;
  margin: 10px;
  justify-content: center;
    align-items: center;
}
.clubLogo{
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  padding: 2px;
  color: #fff;
  border: 2px solid #000;
  text-align: center;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #14a184;
}


.letterCircleClub {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  padding: 8px;
  color: #fff;
  border: 2px solid #000;
  text-align: center;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #14a184;
}
.locationBox{
  display: flex;
    justify-content: start;
  width: 100%;


}
.locationText{
  background-color: #e74c3c;
  padding: 10px;
  color: white;
  border-radius: 0px 50px 50px 0px;
  margin-bottom: 5px;
}