.card{
    max-width: 800px;
}
.padding {
    margin: 0px 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;

}

.card-img-top1 {
    height: 300px
}

.card-no-border .card {
    border-color: #d7dfe3;
    border-radius: 4px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05)
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem
}

.pro-img {
    margin-top: -80px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.little-profile .pro-img .detailImg {
    width: 128px;
    height: 128px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 100%
}

.letterCircleClubDetail{
    border-radius: 50%;
    width: 128px;
    height: 128px;
    padding: 8px;
    color: #fff;
    border: 2px solid #000;
    text-align: center;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #14a184;
}
html body .m-b-0 {
    margin-bottom: 0px
}

h3 {
    line-height: 30px;
    font-size: 21px
}


.m-t-20 {
    margin-top: 20px
}

.text-center {
    text-align: center !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #455a64;
    font-family: "Poppins", sans-serif;
    font-weight: 400
}
.selectBox{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}