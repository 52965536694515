.userList{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.userBox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.userItem{
  padding: 10px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  background: #ecf0f1;
}
.userDp{
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  padding: 2px;
  color: #fff;
  border: 1px solid #000;
  text-align: center;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #14a184;
  
}

.letterCircleUser {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  padding: 8px;
  color: #fff;
  border: 2px solid #000;
  text-align: center;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #14a184;
}
.locationBox{
  display: flex;
  justify-content: start;
  width: 100%;
  position: absolute;
  top: 0;


}
.locationText{
  background-color: #e74c3c;
  padding: 5px;
  color: white;
  border-radius: 0px 50px 50px 0px;
  margin-bottom: 5px;
}
.card-title{
    font-size: 14px;
}