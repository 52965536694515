.teamCompMain{
    padding: 0;
    /* height: 100%; */
    /* width: 1800; */
    background-color: #34ADFF;
    color: white;
    /* background-position:cover; */
    background: url("https://sportzmitra-public.s3.ap-south-1.amazonaws.com/Background.png");
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
}
.teamBoxExpand{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    height: 100%;
}
.teamSection .team-boxed{
    height: 100%;
    background-color: rgba(255, 255, 255, .25);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    }

.teamSection  .team-boxed .item .box {
    background-color:transparent;
  text-align:center;
  padding:30px;
  margin-bottom:30px;
  width: 100%;

}
.teamSection  .team-boxed .item .name{
color: #000000;
}
.teamSection  .team-boxed .font-weight-bolder{
    color: #000000;

}
.teamSection .team-boxed .team-text{
    color: #000000;

}


.teamBoxExpand .name{
    font-size: 30px;
    justify-content: center;
    text-align: center;
    text-transform:uppercase
}
.teamBoxExpand span{
    font-size: 20px !important;
}
.teamBoxExpand img{
    border-radius: 0 !important;
    height: 200px;
}
.teamSection{
    align-items: center;
    width: 30%;
    height: 100%;
    display: flex;
    padding-top: 14px;
    flex-direction: column;

}
.textCenter{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    padding-left: 5px;
}
.teamLogBox{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #01203B;
    width: 300px;
}
.countBox{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #01203B;
    width: 300px;
    height: 150px;
    margin-top: 20px;

}
.teamName {
    padding-left: 5px;
    font-size: 2rem;
    font-family: 'Poppins';
    color: #FA9637;
    text-transform: uppercase;
}

.ownerName {
    padding-left: 5px;
    font-size: 1.5rem;
    color: #FFFFFF;
    text-transform: uppercase;

}
.soldUnsoldSection{
    width: 70%;
    height: 100%;
    overflow: scroll;

}
.cardPlayerBox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
}
.cardPlayer{
    display: flex;
    flex-direction: column;
    margin: 15px;

}
.logo-box{
    width: 300px;
    height: 300px;
}
.imgContainer{
    border-radius: 0px;
    width: 100%;
    height: 200px;
    width: 200px;
  
}
.skewed{
    width: 150px;
    height: 220px;
    margin: 5px 0 -50px 0;
    border-radius: 30px;
    /* transform: skewY(15deg); */
    overflow: hidden;
}

#img{
    width: 100%;
    height: 100%;
    /* transform: skewY(-15deg); */
    /* background-image: url("https://images.unsplash.com/photo-1613483812364-896455b2b309?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"); */
    background-size: 100%;
    background-position: center;
    transition: all 0.3s ease;
}

#img:hover{
    background-size: 110%;
}
.textBox{
    background-color: #01203B;

}

.contentImg{
    width: 200px;
    height: 100px;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-end;
    background-color: #01203B;
    padding-left: 15px;
}
.contentImg h2{
    color: #FFFFFF;
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: bold;
    text-transform:capitalize !important;
    font-family: Poppins;
}
.contentImg span{
    font-size: 1rem;
    color: #E43540
}

.triangle-topleft {
	width: 0;
	height: 0;
    border-bottom: 25px solid #FA9637;
    border-left: 25px solid transparent;
}
.contentImg .links{
    width: 80%;
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}

.contentImg .links i{
    color: #b95808;
    font-size: 30px;
    border-radius: 20px;
    transition: all 0.3s ease;
}

.contentImg .links i:hover{
    transform: scale(1.2);
}

.teamNameBox{
    display: flex;
    justify-content: center;
}
.teamNameBox .profile-box{
    display: flex;
    justify-content: center;
    width: 300px;
    background-color: #2ecc71;
    color: white;

}