
.lst-kix_list_2-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-7>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_2-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: "o  "
}

.lst-kix_list_1-2>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_1-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_1-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_1-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_1-6>li:before {
    content: "\0025aa   "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_2-1>li:before {
    content: "o  "
}

.lst-kix_list_1-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-3>li:before {
    content: "\0025aa   "
}

ol {
    margin: 0;
    padding: 0
}


.c13 {
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c1 {
    padding-top: 0pt;
    padding-bottom: 11pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c15 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.c17 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 13.5pt;
    font-family: "Helvetica Neue"
}

.c16 {
    padding-top: 10pt;
    padding-bottom: 17pt;
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-family: "Trebuchet MS";
    font-style: normal
}

.c4 {
    padding-top: 0pt;
    padding-bottom: 11pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c8 {
    padding-top: 0pt;
    padding-bottom: 11pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c7 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.c22 {
    text-decoration-skip-ink: none;
    font-size: 9pt;
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline
}

.c11 {
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.c10 {
    background-color: #ffffff;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.c2 {
    font-size: 9pt;
    color: #000000;
    font-weight: 700
}

.c6 {
    color: inherit;
    text-decoration: inherit
}

.c3 {
    color: #000000;
    font-size: 9pt
}

.c20 {
    padding: 0;
    margin: 0
}

.c18 {
    font-family: "Arial";
    font-weight: 400
}

.c19 {
    max-width: 100%;
    padding: 72pt 72pt 72pt 72pt
}

.c14 {
    height: 11pt
}

.c12 {
    background-color: #ffffff
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 21pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 13pt;
    padding-bottom: 10pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 10pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 8pt;
    color: #666666;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 8pt;
    -webkit-text-decoration-skip: none;
    color: #666666;
    text-decoration: underline;
    font-size: 11pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    text-decoration-skip-ink: none;
    font-family: "Trebuchet MS";
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Trebuchet MS";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}