body{
    color: #1a202c;
    text-align: left; 
}
.main-body {
    padding: 15px;
}
.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}

.mainBoxProfile {
    background-color: rgb(255, 255, 255);
    color: rgb(52, 49, 76);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important;
}
.profileRow {
    padding: 24px;
    margin: -8px;
    flex-wrap: wrap;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.profileDetailBox{
    -webkit-box-align: center;
    align-items: center;
    margin: 8px;
    display: flex;
}
.profileImgBox {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    width: 48px;
    height: 48px;
}
.profileImage {
    width: 48px;
    height: 48px;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}
.profiledetailBox {
    margin-left: 16px;
}
.profileh5 {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: none;
    white-space: normal;
}
.profilep {
    margin-bottom: 0px;
    font-size: 14px;
    white-space: normal;
    color: rgba(52, 49, 76, 0.54);
    margin-top: 8px;
    font-weight: normal;
    text-transform: capitalize;
}
.text-secondary{
    text-transform: capitalize;
}
.text-capitalize{
    text-transform: capitalize;
}