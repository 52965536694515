
.rc-table-content table{
    width: 100%;
  }
  .rc-table-content tr{
    margin: 14px !important;
    padding: 10px;
    border: 1px #2c3e50 solid;
  }
  .rc-table-content td{
    margin: 14px !important;
    padding: 10px;
    border: 1px #2c3e50 solid;
  }
  .rc-table-thead{
    background: #1abc9c;
    color: white;
  
  }
  .rc-table-cell{
    margin: 14px !important;
    padding: 10px;
    border: 1px #2c3e50 solid;
    text-transform: capitalize;
  }

  .tableButtonPrimary{
    font-size: 14px;
    color: white;
    box-shadow: 0 5px 5px 0 rgba(255, 255, 255, 0.5);
    font-weight: 300;
    padding: 5px 10px;
    border-radius: 100px;
    transition: 300ms;
    cursor: pointer;
    background: #2ecc71;
    text-decoration: none;
    margin: 0px 10px;
  }

  .tableButtonDanger{
    font-size: 14px;
    color: white;
    box-shadow: 0 5px 5px 0 rgba(80, 123, 252, 0.50);
    font-weight: 300;
    padding: 5px 10px;
    border-radius: 100px;
    transition: 300ms;
    cursor: pointer;
    background: #c0392b;
    text-decoration: none;
    margin: 0px 10px;
  }
  .boxImageSize{
    height: 250px !important;
  }
  .letterCircleClubBox {
    height: 250px;
    padding: 8px;
    color: #fff;
    border: 2px solid #ffff;
    text-align: center;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #797C34;
  }
  .itemName{
    font-size: 18px;
    color: #000;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
  }
  .tableButtonDisable{
    font-size: 14px;
    color: white;
    box-shadow: 0 5px 5px 0 rgba(80, 123, 252, 0.50);
    font-weight: 300;
    padding: 5px 10px;
    border-radius: 100px;
    transition: 300ms;
    cursor: pointer;
    background: #95a5a6;
    text-decoration: none;
    margin: 0px 10px;
    cursor: not-allowed;
  }
  .noDataFound {
    display: flex;
    /* flex: 1 1; */
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    flex-direction: column;
    margin-top: 10%;
  }
  .noDataFound b {
    padding: 15px 0px;
  }
  .btn-primary {
    background: #50ac00;
    display: inline-block;
    padding: 8px 25px 10px 25px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border-color: #50ac00;
  }
 .btn-primary:hover {
    background: #2ecc71 !important;
    color: #fff !important;
  }
  .btn-danger {
    background: #c0392b;
    display: inline-block;
    padding: 8px 25px 10px 25px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border-color: #50ac00;
  }
 .btn-danger:hover {
    background: #e74c3c !important;
    color: #fff !important;
  }
  .btn-disable {
    background: #bdc3c7;
    display: inline-block;
    padding: 8px 25px 10px 25px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border-color: #50ac00;
  }
 .btn-disable:hover {
    background: #7f8c8d !important;
    color: #fff !important;
  }
  .errorMsgRegister{
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .errorMsg{
    color: red;
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: capitalize;
  }
  .intro{
    text-transform: capitalize;
    text-align: left;
  }