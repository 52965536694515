.requestList{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.clubItem{
  padding: 10px;
  margin: 10px;
  justify-content: center;
    align-items: center;
}
.clubLogo{
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  padding: 2px;
  color: #fff;
  border: 2px solid #000;
  text-align: center;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #14a184;
}


table{
  background: #ffff;
}

.tableCs{
  overflow: scroll;
}
tr:nth-child(even) {
  background-color: #e0e0e0;
}
th {
  background-color: #04AA6D;
  padding: 10px;
  color: white;
  padding: 5px;
}
td {
  padding: 10px;
}
.pagination{
  display: flex;
  justify-content: center;
  background: #e4e4e4;
  height: 50px;
  align-items: center;
}
.pagination button{
  display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: all .3s;
}

/* ---------- */



/* USER LIST TABLE */
.user-list tbody td > img {
    position: relative;
	max-width: 120px;
	height: 120px;
	float: left;
	margin-right: 15px;
}
.user-list tbody td .user-link {
	display: block;
	font-size: 1.6em;
    font-weight: 700;
    color: #12263a;
}
.user-list tbody td .user-subhead {
	font-size: 0.875em;
	font-style: italic;
}
.tableBox{
	position: absolute;
    overflow-y: scroll;
    width: 100%;
}

/* TABLES */
.main-box .table {
    border-collapse: separate;
}
.main-box .table thead{
  background-color: #50ac00;
}
.main-box .table thead td{
	background-color: #50ac00;
  }
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
	background-color: #eee;
}
.main-box .table thead > tr > th {
	border-bottom: 1px solid #C2C2C2;
	padding-bottom: 0;
	
}
.main-box .table tbody > tr > td {
	font-size: 1em;
    font-weight: 700;
    color: #12263a;
	background: #f5f5f5;
	border-top: 10px solid #fff;
	vertical-align: middle;
	padding: 12px 8px;
}
.main-box .table tbody > tr > td:first-child,
.main-box .table thead > tr > th:first-child {
	padding-left: 20px;
}
.main-box .table thead > tr > th span {
	display: inline-block;
	padding: 0 5px;
	padding-bottom: 5px;
	font-weight: normal;
}
.main-box .table thead > tr > th > a span {
	color: #344644;
}
.main-box .table thead > tr > th > a span:after {
	content: "\f0dc";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	margin-left: 5px;
	font-size: 0.75em;
}
.main-box .table thead > tr > th > a.asc span:after {
	content: "\f0dd";
}
.main-box .table thead > tr > th > a.desc span:after {
	content: "\f0de";
}
.main-box .table thead > tr > th > a:hover span {
	text-decoration: none;
	color: #2bb6a3;
	border-color: #2bb6a3;
}
.table.table-hover tbody > tr > td {
	-webkit-transition: background-color 0.15s ease-in-out 0s;
	transition: background-color 0.15s ease-in-out 0s;
}
.main-box .table tbody tr td .call-type {
	display: block;
	font-size: 0.75em;
	text-align: center;
}
.main-box .table tbody tr td .first-line {
	line-height: 1.5;
	font-weight: 400;
	font-size: 1.125em;
}
.main-box .table tbody tr td .first-line span {
	font-size: 0.875em;
	color: #969696;
	font-weight: 300;
}
.main-box .table tbody tr td .second-line {
	font-size: 0.875em;
	line-height: 1.2;
}
.main-box .table a.table-link {
	margin: 0 5px;
	font-size: 1.125em;
}
.main-box .table a.table-link:hover {
	text-decoration: none;
	color: #2aa493;
}
.main-box .table a.table-link.danger {
	color: #fe635f;
}
.main-box .table a.table-link.danger:hover {
	color: #dd504c;
}

.table-products tbody > tr > td {
	background: none;
	border: none;
	border-bottom: 1px solid #ebebeb;
	-webkit-transition: background-color 0.15s ease-in-out 0s;
	transition: background-color 0.15s ease-in-out 0s;
	position: relative;
}
.table-products tbody > tr:hover > td {
	text-decoration: none;
	background-color: #f6f6f6;
}
.table-products .name {
	display: block;
	font-weight: 600;
	padding-bottom: 7px;
}
.table-products .price {
	display: block;
	text-decoration: none;
	width: 50%;
	float: left;
	font-size: 0.875em;
}
.table-products .price > i {
	color: #8dc859;
}
.table-products .warranty {
	display: block;
	text-decoration: none;
	width: 50%;
	float: left;
	font-size: 0.875em;
}
.table-products .warranty > i {
	color: #f1c40f;
}
.main-box .table tbody > tr.table-line-fb > td {
	background-color: #9daccb;
	color: #262525;
}
.main-box .table tbody > tr.table-line-twitter > td {
	background-color: #9fccff;
	color: #262525;
}
.main-box .table tbody > tr.table-line-plus > td {
	background-color: #eea59c;
	color: #262525;
}
.table-stats .status-social-icon {
	font-size: 1.9em;
	vertical-align: bottom;
}
.table-stats .table-line-fb .status-social-icon {
	color: #556484;
}
.table-stats .table-line-twitter .status-social-icon {
	color: #5885b8;
}
.table-stats .table-line-plus .status-social-icon {
	color: #a75d54;
}